.user-agreement{
    &-back-button{
        display: none;
    }
    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 59px;
        color: #FFFFFF;
        margin-bottom: 40px;
    }
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #FFFFFF;
        margin-bottom: 20px;
    }
}

@media (max-width: 1040px) {
    .user-agreement{
        max-width: 420px;

        &-back-button{
            margin-bottom: 33px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h4{
                margin-bottom: 6px;
                margin-right: 20px;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                letter-spacing: 0.02em;

                color: #FFFFFF;
            }
        }

        h1{
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 39px;
            color: #FFFFFF;
            margin-bottom: 40px;
        }
        p{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            margin-bottom: 20px;
        }
    }
}


@media (max-width: 1040px) {
    .user-agreement{
        padding: 0 10px;
    }
}