.pools-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    background: #232340;
    border-radius: 20px;
    width: 100%;
    min-height: 208px;

    padding: 12px 15px;

    &-from-to {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &-imgs {
            display: flex;
            margin-right: 25px;
            position: relative;

            &-img-first {
                max-height: 26px;

            }

            &-img-second {
                max-height: 26px;
                position: absolute;
                right: -19px
            }
        }

        &-text {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }
    }

    &-change-count {
        margin-top: 10px;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #40A5F3;
    }


    &-statistic-items {

        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-item {
            background: rgba(34, 35, 66, 0.7);
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            padding: 4px 10px;

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }

            span {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.02em;

                color: #FFFFFF;
            }

        }
    }

}