.function-button_wrap {
    position: relative;

    &-main {
        cursor: pointer;
        min-width: 65px;
        height: 32px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        padding: 0 6px;

        background: #222342;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;

        h5 {
            margin-left: 2px;
            padding: 0 3px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;

            letter-spacing: 0.02em;

            color: #FFFFFF;

            opacity: 0.7;
        }

        &-right{
            height: 28px;
            background: #19172F;
            border-radius: 20px;

            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            padding: 0 6px;
            h5{
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;

                color: #40A5F3;
            }
        }
    }
}

.grad{
    background: linear-gradient(90deg, #40A5F3 0%, #164EDD 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

@media (max-width: 1040px) {
    .function-button_wrap {

        &-main {
            width: 100%;
            min-width: 65px;
            height: 32px;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            padding: 0 6px;

            background: #222342;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 30px;

            h5 {
                margin-left: 2px;
                padding: 0 3px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;

                letter-spacing: 0.02em;

                color: #FFFFFF;

                opacity: 0.7;
            }

            &-right{
                height: 28px;
                background: #19172F;
                border-radius: 20px;

                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 5px;
                padding: 0 6px;
                h5{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 140%;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.02em;

                    color: #40A5F3;
                }
            }
        }
    }
}
