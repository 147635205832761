.short-coin-card-item {
    width: 100%;
    min-height: 100px;
    padding: 18px 25px;
    background: #2a2a47;
    border-radius: 20px;

    display: flex;
    align-items: center;

    &-center {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-token {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;

            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;

            img {
                max-width: 35px;
            }
        }

        &-count {
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }

    }

}