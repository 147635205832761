.coin-card-item {
    width: 100%;
    min-height: 162px;
    padding: 18px 25px;
    background: #2a2a47;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-title {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            opacity: 0.7;
        }

        &-info {
            display: flex;
            align-items: center;
            gap: 4px;

            &-balance {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                opacity: 0.7;
            }
            &-max {
                padding: 0 4px;
                background: #335b8c;
                border-radius: 4px;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                letter-spacing: 0.02em;

                color: #40A5F3;

                opacity: 0.7;
            }
        }
    }

    &-center {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-token{
            cursor: pointer;
            display: flex;
            flex-direction: row;
            gap: 6px;
            align-items: center;

            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;

            img{
                max-width: 35px;
            }
        }
        &-count{
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }

    }

    &-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-currency{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            opacity: 0.7;

            input{
                width: 80%;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                opacity: 0.7;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            input[type=number] {
                -moz-appearance: textfield;
            }
        }
        &-count{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            opacity: 0.7;
        }

    }
}