.feedback{
    width: 100%;
    background: url('../../assets/img/feedbackbck.png') no-repeat;
    background-position: right -20px;

    &-container{
        min-height: 280px;
        max-width: 756px;
        h2{
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 39px;
            color: #FFFFFF;
            margin-bottom: 7px;
        }
        h4{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            opacity: 0.7;
            margin-bottom: 30px;
        }

        &__inputs{
            display: flex;
            flex-direction: column;
            gap: 40px;
        }
    }
}

@media (max-width: 1040px) {
    .feedback{
        background: none;

        &-container{
            min-height: 280px;
            max-width: 756px;
            h2{
                font-style: normal;
                font-weight: 700;
                font-size: 26px;
                line-height: 39px;
                color: #FFFFFF;
                margin-bottom: 7px;
            }
            h4{
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                opacity: 0.7;
                margin-bottom: 30px;
            }

            &__inputs{
                display: flex;
                flex-direction: column;
                gap: 26px;
            }
        }
    }
}



@media (max-width: 1040px) {
    .feedback{
        padding: 0 10px;
    }
}