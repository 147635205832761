.error-modal{
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    opacity: 1;
    pointer-events: all;


    &-content {
        min-height: 400px;
        min-width: 100px;
        max-width: 490px;
        margin: 0 15px;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background: #19172F;
        box-shadow: 20px 10px 60px rgba(235, 87, 87, 0.5);
        border-radius: 30px;

        padding: 44px 20px;

        &-title{
            text-align: center;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            margin-bottom: 20px;

        }

        &-loader{
            margin: 50px 0;
        }
        &-icon{
            margin: 50px 0;
        }

        &-desc{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            opacity: 0.7;

            margin-bottom: 20px;
        }
    }
}

