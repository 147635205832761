.faq {
    position: relative;

    &-img {
        position: absolute;
        z-index: -10;
        top: -30px;
        right: -100px;
    }
}


@media (max-width: 1400px) {
    .faq {
        &-img {
            right: 0;
        }
    }

}

@media (max-width: 1040px) {
    .faq {
        &-img {
            display: none;
        }
    }

}


@media (max-width: 1040px) {
    .faq{
        padding: 0 10px;
    }
}