.container {
    padding: 40px 0 0;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.test{

}

.main-header {
    padding: 0 20px;
}

.main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 50px 20px;
}

.main-footer {
    padding: 50px 20px;
}

.main-footer_mob {
    left: 0;
    right: 0;
    margin: 0 auto;
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    background-color: #1E1B38;

    text-align: center;
    padding-top: 20px;
}

@media (max-width: 1040px) {
    .main-footer_mob {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container {
        padding: 0 0;
        margin-bottom: 70px;
    }
    .main-header{
        display: none;
    }
    .main-footer{
        display: none;
    }
    .main-wrapper{
        max-width: 420px;
        padding: 20px 0;
    }
}

@media (max-width: 556px) {

}
