.warning-block{
    background: #592c3d;
    border-radius: 10px;
    width: 100%;
    padding: 5px 11px;

    display: flex;


    &-icon{
        margin-right: 11px;
    }
    &-text{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        opacity: 0.7;
    }
}