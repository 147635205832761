.pools {
    margin-top: 15px;

    &-connected {
        &-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px;
        }
    }

    &-unconnected {

        &-info {
            margin: 40px 0 20px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h4 {
                margin-top: 20px;
                text-align: center;
                max-width: 230px;

                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                opacity: 0.7;
            }
        }
    }

    &-confirm {

        &-from-to {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;

            &-left {
                display: flex;
                align-items: center;

                &-imgs {
                    display: flex;
                    margin-right: 35px;
                    position: relative;

                    &-img-first {
                        max-height: 36px;
                    }

                    &-img-second {
                        max-height: 36px;
                        position: absolute;
                        right: -25px
                    }
                }

                &-text {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 140%;
                    letter-spacing: 0.02em;
                    color: #FFFFFF;
                }
            }

            &-right {
                &-text {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 140%;
                    letter-spacing: 0.02em;
                    color: #40A5F3;
                }
            }

        }

        &-valume {
            background: rgba(34, 35, 66, 0.7);
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            margin-top: 4px;
            width: 100%;
            height: 52px;

            padding: 7px 0;

            &-title {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 140%;
                letter-spacing: 0.02em;

                color: #FFFFFF;
            }

            &-value {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.02em;

                color: #FFFFFF;
            }
        }

        &-stats {
            display: flex;
            gap: 10px;
            &-stat1 {
                width: 40%;

                background: rgba(34, 35, 66, 0.7);
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
                border-radius: 10px;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                margin-top: 4px;
                height: 52px;
                padding: 7px 0;
                &-title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 140%;
                    letter-spacing: 0.02em;

                    color: #FFFFFF;
                }

                &-value {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                    letter-spacing: 0.02em;

                    color: #FFFFFF;
                }
            }

            &-stat2 {
                width: 60%;

                background: rgba(34, 35, 66, 0.7);
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
                border-radius: 10px;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                margin-top: 4px;
                height: 52px;
                padding: 7px 0;
                &-title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 140%;
                    letter-spacing: 0.02em;

                    color: #FFFFFF;
                }

                &-value {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                    letter-spacing: 0.02em;

                    color: #FFFFFF;
                }
            }
        }

        &-income{
            background: rgba(34, 35, 66, 0.7);
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            margin-top: 4px;
            width: 100%;
            height: 52px;

            padding: 7px 0;

            &-title {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 140%;
                letter-spacing: 0.02em;

                color: #FFFFFF;
            }

            &-value {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.02em;

                color: #FFFFFF;
            }
        }
    }

}


@media (max-width: 1040px) {
    .pools{
        padding: 0 10px;
    }
}