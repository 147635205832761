.bottom-nav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 42px;
    max-width: 420px;
    min-width: 280px;
    margin-bottom: 10px;
    &__link {
        font-size: var(--fontSizeSubTitle);
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &-dote{
            position: absolute;
            margin-bottom: 4px;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: #40A5F3;
            top: -10px;
        }
    }

    &__link {
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        opacity: 0.7;
        font-family: var(--firstFont);
        font-weight: 500;
        color: #fff;
        display: flex;
        justify-content: center;

    }

    .active {
        color: #40A5F3;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
    }
}

@media (max-width: 360px) {
    .bottom-nav {
        gap: 25px;
        max-width: 360px;
    }
}