.swaper-block{
    background: linear-gradient(180deg, #222342 0%, rgba(34, 35, 66, 0) 130%);
    /* тень й */

    filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.25));
    border-radius: 20px;
    padding: 15px;
    min-height: 520px;
}

