.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.header_left-bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__logo {
        cursor: pointer;
        margin-right: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;

        h1 {
            margin-left: 5px;
            font-family: var(--firstFont);
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            text-transform: capitalize;
        }
    }

}

.header_right-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}