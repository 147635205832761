.checkbox{
	display: flex;
}

.checkbox__wrap{
	width: 20px;
	height: 20px;
	background: #3F4157;
	border: 1px solid #3F4157;
	border-radius: 6px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.checkbox__wrap_check{
	background: #40A5F3;
	border-radius: 6px;
}

.checkbox__check{
	width: 14px;
	height: 10px;
	fill: #FAF7F0;
}

.checkbox__text{
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	letter-spacing: 0.02em;
	color: #FFFFFF;
	margin-left: 10px;
}
