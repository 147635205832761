.header-mob {
    display: none;

    &-top {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 5px;

    }

    &-bottom {
        margin-bottom: 20px;

    }

}


.header_right-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

@media (max-width: 1040px) {
    .header-mob {
        display: block;
    }
}