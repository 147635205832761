.confirm-modal {
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
    opacity: 1;
    pointer-events: all;


    &-content {
        min-height: 200px;
        min-width: 100px;
        max-width: 490px;
        margin: 0 15px;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background: #19172F;
        box-shadow: 20px 10px 60px rgba(64, 165, 243, 0.5);
        border-radius: 30px;

        padding: 44px 20px;


        &-title {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            letter-spacing: 0.02em;
            color: #FFFFFF;

        }

        &-img {
            margin: 27px 0;

        }

        &-text {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            opacity: 0.7;
        }

        &-text-extra {
            margin: 20px 0 25px;

            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }


    }
}

@media (max-width: 1040px) {
    .confirm-modal {

        &-content {
            max-width: 400px;

            &-title {
                font-size: 18px;
            }

            &-img{

            }

            &-text {
                font-size: 14px;
            }

            &-text-extra {
                margin: 20px 0 25px;

                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }
        }
    }
}
