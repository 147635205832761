.swaper-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 500px;
    max-width: 100%;
    min-height: 300px;
    max-height: 100%;
    height: auto;
}

.swaper {
    margin-top: 15px;

    &-cards {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;

        &-arrow {
            position: absolute;
            width: 49px;
            height: 49px;
            background-color: #40A5F3;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &-equals {
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 20px 0;
        width: 100%;
        padding: 9px 22px;
        background-color: #25243d;
        border-radius: 10px;
    }
}


.check-token {

    &-line {
        margin: 20px 0;
        height: 1px;
        width: 100%;
        background-color: #FFFFFF;
        opacity: 0.3;
    }
}

.swaper-confirm {
    height: 100%;

    &-line {
        margin: 20px 0;
        height: 1px;
        width: 100%;
        background-color: #FFFFFF;
        opacity: 0.3;
    }
}

@media (max-width: 1040px) {
    .swaper-wrapper {
        padding: 0 10px;
    }
}
