.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    transition: height 0.5s ease;

    overflow: hidden;
    z-index: 1000;

    background: #19172F;
    box-shadow: 20px 10px 80px rgba(64, 165, 243, 0.5);
    border-radius: 30px 30px 0px 0px;

    &.open {
        height: 450px;
        transition: height 0.5s ease;
    }
}

.modal-content {
    min-height: 450px;
    max-width: 420px;
    padding: 30px 20px;
    margin: 0 auto;
    background-color: #19172f;


    &-title{
        margin: 30px 0 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        opacity: 0.7;
    }
}

.app {
    padding-bottom: 120px; /* Добавьте отступ внизу страницы для размещения модального окна */
}