.system {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 260px;
    &-item {
        cursor: pointer;
        width: 100%;
        max-width: 748px;
        min-width: 260px;

        min-height: 100px;
        border-radius: 20px;
        background-color: #222037;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;

        h4 {
            margin-right: 30px;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }
    }
}


@media (max-width: 1040px) {
    .system{
        padding: 0 10px;
    }
}