.swaper-nav {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .swaper-nav-tab{
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        opacity: 0.3;
    }

    .active {
        font-weight: 600;
        opacity: 1;
    }
}