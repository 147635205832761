.textarea{
    width: 100%;

    &__title{
        margin-bottom: 5px;

        font-family: var(--firstFont);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #6E6B7B;

    }
}

.textarea__wrap{
    display: flex;
    align-items: center;
    padding: 0px 15px;
    background: #3F4157;
    border: 1px solid #3F4157;
    border-radius: 10px;
    height: 180px;

    &--stopwatch {
        height: 45px;
        padding: 0 3px;
    }
}

.textarea__left{
    width: 20px;
    margin-right: 12px;
    height: 150px;
}

.textarea__wrap_focus{
    border: 1px solid #4790F2;
}

.textarea__wrap_error{
    border: 1px solid rgba(255, 60, 0, 0.6);
}

.textarea__input{
    font-family: var(--firstFont);
    font-style: normal;
    font-weight: 400;

    resize: none;
    border: none;
    outline: none;
    height: 150px;
    overflow:hidden;

    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;

    color: #FFFFFF;

    flex: 1;
    background: transparent;

    &--stopwatch {
        height: 40px;
        overflow-y: auto;
        line-height: 15px;
    }
}

.textarea__input::placeholder{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.4);
}

.textarea .textarea__input:-webkit-autofill {
    transition: background-color 60000000s 0s, color 60000000s 0s;
}

.textarea__error{
    padding: 8px 23px 0px;
}

.textarea__error-text{
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: rgba(255, 60, 0, 0.6);
}
