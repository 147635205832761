.input{

}

.input__wrap-login{
    display: flex;
    align-items: center;
    padding: 0px 22px;
    background: #3F4157;
    border: 1px solid #3F4157;
    border-radius: 10px;
    height: 50px;
}

.input__wrap_focus{
    border: 1px solid #4790F2;
}

.input__wrap_error{
    border: 1px solid rgba(255, 60, 0, 0.6);
}
.box-shadow{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.input__input{
    height: 20px;
    font-weight: 400;
    flex: 1;
    background: transparent;
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;

    color: #FFFFFF;
}

.input__input::placeholder{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 0.4);
}

.input .input__input:-webkit-autofill {
    transition: background-color 60000000s 0s, color 60000000s 0s;
}

.input__left{
    width: 24px;
    height: 24px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.input__right{
    width: 20px;
    height: 20px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input__right_clicked{
    cursor: pointer;
}

.input__error{
    padding: 8px 23px 0px;
}

.input__error-text{
    font-family: var(--firstFont);
    font-weight: 400;
    font-size: var(--fontSizeText);
    color: rgba(255, 60, 0, 0.6);
}
