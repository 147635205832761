.faq-item{
    width: 100%;
    max-width: 748px;
    min-width: 260px;
    background: #222037;
    border-radius: 20px;
    min-height: 71px;
    margin-bottom: 10px;

    cursor: pointer;

    padding: 20px 35px;

    z-index: 20;

    &-titleBlock{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

       h2{
           font-style: normal;
           font-weight: 600;
           font-size: 22px;
           line-height: 140%;

           display: flex;
           align-items: center;
           letter-spacing: 0.02em;

           color: #FFFFFF;
       }
    }

    &-textBlock{
        margin-top: 21px;
        p{
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: start;
            display: flex;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            opacity: 0.7;
        }
    }
}

@media (max-width: 1040px) {
    .faq-item{

        &-textBlock{
            p{
                font-size: 12px;
            }
        }
    }
}