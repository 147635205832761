.landing {
    background: url('../../assets/img/landingIntroBck1.png') no-repeat;
    background-position: left top -30px;
    position: relative;
    width: 100%;

    .landing-intro-back {
        display: none;
    }

    .landing__logo {
        display: none;
    }

    &-intro {
        max-width: 559px;
        min-width: 350px;
        display: flex;
        flex-direction: row;
        margin-top: 150px;

        &-left {
            &-title {
                font-family: 'Atyp Display', sans-serif;
                font-style: normal;
                font-weight: 900;
                margin-bottom: 40px;
                line-height: 100px;
                font-size: 90px;
                display: flex;
                align-items: center;

                color: #FFFFFF;

            }

            &-desc {
                margin-bottom: 20px;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                opacity: 0.7;
            }

            &-button {
                height: 46px;
            }
        }

        &-right {
            z-index: -10;
            position: absolute;
            top: -100px;
            right: -150px;

            &-img {
                max-width: 900px;

                &-mob {
                    display: none;
                }
            }
        }
    }

    &-price {
        margin-top: 98px;
        width: 100%;
        min-height: 172px;
        background: #222342;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(4, 1fr); /* 4 items per row on larger screens */
        grid-gap: 20px;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: center;

            &-count {
                font-style: normal;
                font-weight: 800;
                font-size: 60px;
                line-height: 90px;
                color: #1ABCFE;
            }

            &-desc {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #FFFFFF;
                opacity: 0.7;
            }
        }
    }

    &-info {
        margin: 194px 0 300px;
        position: relative;

        &-left {
            max-width: 591px;
            min-width: 280px;

            &-title {
                font-family: 'Atyp Display', sans-serif;
                margin-bottom: 40px;
                font-style: normal;
                font-weight: 900;
                line-height: 100px;
                font-size: 80px;
                display: flex;
                align-items: center;

                color: #FFFFFF;
            }

            &-desc {
                margin-bottom: 20px;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                opacity: 0.7;
            }

            &-button {
                max-width: 329px;
            }
        }

        &-right {
            z-index: -10;
            position: absolute;
            top: -50px;
            right: 0;

            &-img {
                max-width: 700px;
                &-mob{
                    display: none;
                }
            }
        }
    }

    &-fusion {
        background: radial-gradient(84.28% 84.28% at 13.06% 61.85%, #164EDD 0%, #40A5F3 60.42%, #3BADEE 89.28%, #40A5F3 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-content{
            padding: 49px 46px;

            &-title {
                font-family: 'Atyp Display', sans-serif;
                font-style: normal;
                font-weight: 900;
                margin-bottom: 45px;
                font-size: 120px;
                line-height: 100px;
                display: flex;
                align-items: center;

                color: #FFFFFF;
            }

            &-text {
                margin-bottom: 15px;
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }
        }


        &-img {
            position: absolute;
            right: 0;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }

    &-finance {
        margin: 184px 0;
        position: relative;

        &-left {
            max-width: 650px;
            min-width: 350px;

            &-title {
                font-family: 'Atyp Display', sans-serif;
                margin-bottom: 40px;
                font-style: normal;
                font-weight: 900;
                line-height: 100px;
                font-size: 90px;
                display: flex;
                align-items: center;

                color: #FFFFFF;
            }

            &-desc {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                opacity: 0.7;
            }

            &-button {
                max-width: 329px;
            }
        }

        &-right {
            z-index: -10;
            position: absolute;
            top: -100px;
            right: -100px;

            &-img {
                max-width: 700px;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .landing {
        &-intro {
            &-left {
                &-title {
                    margin-bottom: 40px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 80px;
                    font-size: 70px;
                    display: flex;
                    align-items: center;
                    color: #FFFFFF;
                }
            }

            &-right {
                z-index: -10;
                position: absolute;
                top: -190px;
                right: -150px;

                &-img {
                    max-width: 900px;
                }
            }
        }
    }
}


@media (max-width: 1040px) {
    .landing {
        background: none;
        position: relative;
        width: 100%;
        overflow: visible;

        .landing-intro-back {
            display: block;
            overflow: visible;
            position: absolute;
            top: 0;
            right: -80px;
            width: 600px;
            height: 600px;
            z-index: -10;
        }

        .landing__logo {
            cursor: pointer;
            margin-right: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;

            h1 {
                margin-left: 5px;
                font-family: var(--firstFont);
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                text-transform: capitalize;
            }
        }

        &-intro {
            max-width: 271px;
            min-width: 350px;
            margin-top: 31px;
            margin-bottom: 200px;


            &-left {
                &-title {
                    max-width: 310px;
                    font-family: 'Atyp Display', sans-serif;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 60px;
                    font-size: 50px;
                    margin-bottom: 10px;
                }

                &-desc {
                    max-width: 165px;
                    margin-bottom: 16px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 140%;
                }

                &-button {
                    width: 176px;
                    height: 38px;
                }
            }


            &-right {
                z-index: -10;
                position: absolute;
                top: 60px;
                right: 10px;

                &-img {
                    display: none;

                    &-mob {
                        max-width: 350px;
                        display: block;
                    }
                }
            }
        }

        &-price {
            grid-template-columns: repeat(2, 2fr); /* 4 items per row on larger screens */
            grid-gap: 15px;

            &__item {
                display: flex;
                flex-direction: column;
                align-items: center;

                &-count {
                    font-size: 30px;
                    line-height: 45px;
                }

                &-desc {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 15px;
                }
            }
        }

        &-info {
            margin: 60px 0 57px;
            position: relative;

            &-left {
                max-width: 100%;

                &-title {
                    margin-bottom: 20px;
                    font-family: 'Atyp Display', sans-serif;
                    font-weight: 600;
                    line-height: 50px;
                    font-size: 50px;
                }

                &-desc {
                    max-width: 154px;
                    font-size: 12px;
                    line-height: 140%;
                }

                &-button {
                    max-width: 329px;
                    margin-top: 110px;
                }
            }

            &-right {
                z-index: -10;
                position: absolute;
                top: 60px;
                right: 30px;

                &-img {
                    display: none;
                    max-width: 700px;
                    &-mob{
                        max-width: 300px;
                        display: block;
                    }
                }
            }
        }

        &-fusion {

            &-content {

                &-title {
                    margin-bottom: 10px;
                    font-size: 50px;
                    line-height: 50px;
                }

                &-text {
                    font-size: 14px;
                }
            }

            &-img {


            }
        }

        &-finance {
            margin: 60px 0;
            position: relative;

            &-left {
                max-width: 591px;
                min-width: 350px;

                &-title {
                    margin-bottom: 20px;
                    line-height: 50px;
                    font-size: 50px;
                }

                &-desc {
                    max-width: 150px;
                    font-size: 12px;
                }
            }

            &-right {
                z-index: -10;
                position: absolute;
                top: 70px;
                right: 0;

                &-img {
                    max-width: 200px;
                }
            }
        }
    }
}


@media (max-width: 556px) {
    .landing {
        overflow: hidden;
        padding: 0 10px;
        .landing-intro-back {

            width: 500px;
            height: 500px;
        }
        &-intro {

            &-right {
                right: 0;
                &-img {
                    display: none;
                    &-mob {
                        max-width: 340px;
                        display: block;
                    }
                }
            }
        }
        &-price{
        }
        &-info{
        }
        &-fusion {
            &-content{
                padding: 20px 20px;
            }
        }
        &-finance{
        }
    }

}

@media (max-width: 360px) {
    .landing {
        .landing-intro-back {
            top: 100px;
            width: 350px;
            height: 350px;
        }

        &-intro {
            &-left {
                &-title {
                    line-height: 50px;
                    font-size: 38px;
                    margin-bottom: 10px;
                }

            }
            &-right {
                z-index: -10;
                position: absolute;
                top: 130px;
                right: 0px;

                &-img {
                    &-mob {
                        max-width: 250px;
                        display: block;
                    }
                }
            }
        }

        &-info {
            &-left {
                max-width: 100%;

                &-title {
                    line-height: 40px;
                    font-size: 40px;
                }
            }
            &-right {
                z-index: -10;
                position: absolute;
                top: 70px;
                right: 0px;

                &-img {
                    display: none;
                    max-width: 700px;
                    &-mob{
                        max-width: 250px;
                        display: block;
                    }
                }
            }
        }

        &-finance {
            margin: 60px 0;
            position: relative;

            &-left {
                max-width: 591px;
                min-width: 280px;

                &-title {
                    margin-bottom: 20px;
                    line-height: 45px;
                    font-size: 38px;
                }

                &-desc {
                    max-width: 150px;
                    font-size: 12px;
                }
            }

            &-right {
                z-index: -10;
                position: absolute;
                top: 80px;
                right: 0;

                &-img {
                    max-width: 150px;
                }
            }
        }
    }
}