.button{
	position: relative;
	width: 100%;
	border-radius: 14px;

	display: flex;
	justify-content: center;
	align-items: center;

	background: #40A5F3;

	p{
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 180%;
		color: #FFFFFF;
	}

	cursor: pointer;

	&-loader{
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

}

.startIcon{
	margin-right: 5px;
}

button:disabled,
button[disabled]{
	background-color: #1e1c34 !important;
	p{
		color: #757483 !important;
	}
}