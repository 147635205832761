.pie-text{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    opacity: 0.7;
    margin-bottom: 20px;
}

.pie-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;

    &-data{
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-item{
            background: #222342;
            box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
            border-radius: 10px;

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;

            padding: 10px 10px;

            &-text{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
            }

        }
    }
}