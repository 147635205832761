.token-icons{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 11px;

    &-item{
        cursor: pointer;
        padding: 6px 10px;
        border-radius: 20px;
        border: 1px solid #40A5F3;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        img{
            width: 20px;
            height: 20px;
        }
    }
}