.small-button__wrap {
    min-width: 65px;
    height: 32px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 6px;

    background: #222342;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}