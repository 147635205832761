.navbar {
    display: flex;

    &__link {
        font-size: var(--fontSizeSubTitle);
    }

    &__item {
        margin-right: 20px;
        position: relative;

    }

    &__link {
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        opacity: 0.7;
        font-family: var(--firstFont);
        font-weight: 500;
        color: #fff;
        display: flex;
        justify-content: center;

    }

    .active {
        color: #40A5F3;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
    }
}

@media screen and (max-width: 600px) {
    .navbar {

    }
}
