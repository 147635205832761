.drop-down-button__wrap {
    position: relative;

    &-main {
        cursor: pointer;
        min-width: 65px;
        height: 32px;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 0 6px;

        background: #222342;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;

        h5 {
            padding: 0 3px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;

            letter-spacing: 0.02em;

            color: #FFFFFF;

            opacity: 0.7;
        }
    }

    &-drop-list {
        z-index: 10000;
        bottom: -62px;
        position: absolute;
        min-width: 72px;
        min-height: 30px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        background: #1A1830;

        padding: 5px 10px;

        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        li {
            padding: 5px 0;
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;

            letter-spacing: 0.02em;

            color: #FFFFFF;
            text-transform: uppercase;
            opacity: 0.7;
        }

        li:hover {
            color: #40A5F3;
        }
    }
}
