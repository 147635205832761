.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-left-block{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;

        color: #BABFC7;
    }

    &-right-block{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        a{
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;

            color: #BABFC7;
        }
    }
}

.vertical-line{
    width: 2px;
    height: 20px;
    background-color: #fff;
    margin: 0 11px;
}