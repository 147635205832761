.pools-categories{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 11px;

    &-active{
        cursor: pointer;
        padding: 6px 10px;
        background: #222342;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #FFFFFF;
    }
    &-disabled{
        cursor: pointer;
        padding: 6px 10px;
        background: #222342;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 20px;

        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.02em;
        color: #8f8f9e;
    }
}