.extra-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    &-value {
        display: flex;
        align-items: center;
        p{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            opacity: 0.7;
        }
    }

    &-key {
        display: flex;
        gap: 8px;

        &-text{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }
        &-text-extra{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }

        &-change-button{
            cursor: pointer;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #40A5F3;
        }
    }
}
