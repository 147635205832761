.back-button{
    width: 100%;
    padding: 10px 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-icon{
        cursor: pointer;
    }
    &-text{
        margin-left: -10px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.02em;
        color: #FFFFFF;
    }
}