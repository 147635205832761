.token-items{
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-item{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &-info{
            display: flex;
            align-items: center;
            gap: 10px;

            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;


            img{
                max-width: 32px;
                max-height: 32px;
            }
        }
        &-count{
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 140%;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }
    }
}